import Product from "./Pages/Product/Product";
import Solution from "./Pages/Solution/Solution";
import Pricing from "./Pages/Pricing/Pricing";
import Signup from "./Pages/Signup/Signup";
import Login from "./Pages/Login/Login";
import Contact from "./Pages/Contact/Contact";
import About from "./Pages/About/About";

export const routes = [
  { path: "/product", component: Product },
  { path: "/solution", component: Solution },
  { path: "/pricing", component: Pricing },
  { path: "/signup", component: Signup },
  { path: "/login", component: Login },
  { path: "/contact", component: Contact },
  { path: "/about", component: About },
];
