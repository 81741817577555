import React, { useState, useEffect, FunctionComponent } from "react";
import "./Switch.scss";

interface SwitchProps {
  defaultValue?: boolean;
  onChange?: (check: boolean) => void;
}

const Switch: FunctionComponent<SwitchProps> = ({
  defaultValue = true,
  onChange,
}) => {
  const [check, setCheck] = useState<boolean>(defaultValue);

  useEffect(() => {
    if (onChange) onChange(check);
  }, [check, onChange]);

  return (
    <label className="switch">
      <input
        type="checkbox"
        onChange={(x) => setCheck(!check)}
        checked={check}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
