import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Header from "./Components/Header/Header";
import "./App.scss";
import Footer from "./Components/Footer/Footer";
import { routes } from "./routes";

function App() {
  return (
    <div className="App">
      <Header />
      <div className="content">
        <Switch>
          <Route exact path="/" component={Home} />
          {routes.map((r) => (
            <Route path={r.path} component={r.component} key={r.path} />
          ))}
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default App;
