import React from "react";
import "./Submit.scss";

const Submit = () => {
  return (
    <button className="submit_comp" type="submit">
      Submit
    </button>
  );
};

export default Submit;
