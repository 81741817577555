import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Background from "../../assets/img/0-mini.png";
import "./Home.scss";

interface HomeProps {}

const data = {
  title: "Contactless\nCommunication Solution",
};

const Home: FunctionComponent<HomeProps> = () => {
  return (
    <div className="home">
      <div className="home__content">
        <h2 className="home__content__title">{data.title}</h2>
        <h3 className="home__content__details">
          Our Contactless Solution enables your business to provide an
          end-to-end contactless experience to your customers and guests.
        </h3>

        <div className="home__content__button">
          <Link to="/solution" className="home__content__button__link">
            Learn more
          </Link>
        </div>
      </div>
      <img src={Background} className="home__image" alt="background" />
    </div>
  );
};

export default Home;
