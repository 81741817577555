import React, { useEffect, useState } from "react";
import "./Product.scss";
import image1 from "../../assets/img/product/1.png";
import image2 from "../../assets/img/product/2.png";
import image3 from "../../assets/img/product/3.png";
import image4 from "../../assets/img/product/4.png";
import image5 from "../../assets/img/product/5.png";
import image6 from "../../assets/img/product/6.png";
import image7 from "../../assets/img/product/7.png";
import image8 from "../../assets/img/product/8.png";
import image9 from "../../assets/img/product/9.png";

interface DataType {
  sections: {
    name: string;
    items: {
      image: string;
      title: string;
      details: string;
      className?: string;
    }[][];
  }[];
}

const data: DataType = {
  sections: [
    {
      name: "Digital Menu",
      items: [
        [
          {
            image: image1,
            title: "Digital menu & Payment",
            details:
              "Keep guests and staff safe by contactless ordering and payment that doesn't compromise the guest experience. Offer your guests the option to pre- order before arrival or place orders at the table.",
          },
          {
            image: image2,
            title: "Contactless service request",
            details:
              "Scan|Tap platform empowers your guests to ask for a service by scanning a QR-code installed on their table. This unique solution reduces the staff movement by 50% . Your staff would provide improved service to your customer by knowing what service they must deliver.",
          },
        ],
      ],
    },
    {
      name: "Communication",
      items: [
        [
          {
            image: image3,
            title: "Guests Feedback Management",
            details:
              "Collect feedback across your customer’s experience. Ask for customer review when they receive service; this allows managers to find unhappy guests before they can post negatively online. In case the customer has a positive experience, the platform encourages them to share their experience online as Google review, Yelp, TripAdvisor, etc.",
          },
          {
            image: image4,
            title: "Fast and safe check-in",
            details:
              "Replace the paper sign in sheet with a contactless check-in solution via customers’ cell phones. Offer your guests a faster and safer way to check-in from the parking lot in the new normal while keeping social distancing.",
          },
        ],
        [
          {
            image: image7,
            title: "Go fully contactless in the new normal",
            details:
              "Our platform empowers your guests to access dine-in services fully contactless via guests’ mobile devices. It offers a 360-degree view of every guest across their entire on- and off-premise experience. Scan|Tap solution provides a variety of features that you can pick and choose per your specific needs.",
          },
          {
            image: image8,
            title: "Free up space with virtual waiting room",
            details:
              "Our solution enables you to communicate with your customers while waiting to let them know when you’re ready to serve them. It also empowers you to create and use 2-way chat templates for common questions and responses.",
          },
          {
            image: image6,
            title: "Online Reservations The Right Way",
            details:
              "Let guests schedule a time on your calendar in advance. Offer your guests to book appointments and reserve tables from everywhere. For a transparent waitlist and booking, Scan|Tap platform adds appointments to the waitlist and keeps them in harmony.",
          },
        ],
      ],
    },
    {
      name: "COVID",
      items: [
        [
          {
            image: image5,
            title: "Mitigate Spread of COVID-19 via AI Smart Camera",
            details:
              "Check for elevated body temperature, monitor crowd, and detect mask using our smart and fully automated platform. Our solution helps your business and our community to fight against the virus by using smart technology and automated detection solution.\n• Measure customer body temperature \n• Frictionless and non-intrusive\n• Monitor and count people within crowds\n• Detect if masks are worn\n• Detect individuals with elevated body temperature in real-time",
          },
          {
            image: image9,
            title: "Contact Registration & Self- Declaration Certification",
            details:
              "Guests can complete a contact tracing and health self-assessment before arrival. Our solution generate a certification badge for each customer on their cellphone. Scan|Tap platform provides you with a live record update of screening responses and symptom alerts.",
          },
        ],
      ],
    },
  ],
};

const Product = () => {
  const getArray = (arr: string[]) => arr;

  const [x, setX] = useState(0);

  useEffect(() => {
    document.getElementById(data.sections[x].name)?.scrollIntoView({
      behavior: "smooth",
    });
  }, [x]);

  return (
    <div className="product_page">
      <div className="product_page__bar">
        <div
          className="product_page__bar__left"
          onClick={() => {
            setX(x - 1 >= 0 ? x - 1 : 2);
          }}
        >
          {"<"}
        </div>
        <div
          className="product_page__bar__right"
          onClick={() => {
            setX((x + 1) % 3);
          }}
        >
          {">"}
        </div>
        <div
          className="product_page__bar__content"
          style={{ transform: `translateX(${(-25 + 50 * x) * -1}vw)` }}
        >
          {getArray(data.sections.map((x) => x.name)).map((name) => (
            <a className="product_page__bar__content__item" href={`#${name}`}>
              {name}
            </a>
          ))}
        </div>
      </div>
      <div className="product_page__content">
        {data.sections.map((section) => (
          <div className="product_page__content__section" id={section.name}>
            <div className="product_page__content__section__title">
              {section.name}
            </div>
            <div className="product_page__content__section__content">
              {section.items.map((row) => (
                <div
                  style={{ gridTemplateColumns: "1fr ".repeat(row.length) }}
                  className="product_page__content__section__content__row"
                >
                  {row.map((item) => (
                    <div
                      className={`product_page__content__section__content__row__item ${
                        row.length === 1 && "single"
                      }`}
                    >
                      <img
                        src={item.image}
                        alt={item.title}
                        className="product_page__content__section__content__row__item__image"
                      />
                      <div className="product_page__content__section__content__row__item__content">
                        <div className="product_page__content__section__content__row__item__content__title">
                          {item.title}
                        </div>
                        <div className="product_page__content__section__content__row__item__content__details">
                          {item.details}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
