import React from "react";
import "./About.scss";
import image from "../../assets/img/1.png";
import { ReactComponent as ProfileIcon } from "../../assets/svg/about/profile.svg";
import { ReactComponent as StarIcon } from "../../assets/svg/about/star.svg";
import { ReactComponent as WalletIcon } from "../../assets/svg/about/wallet.svg";

const data = {
  title: "About us",
  image: image,
  detail:
    "Scan|Tap has helped service organizations optimize their front-of-house service models using cost-effective, agile, contactless technology. Our customers include food and restaurant establishments, service and maintenance businesses.",
  note:
    "Scan|Tap platform provides organization the ability to use technology and data analytics as a competitive advantage. Efficient contactless solution enables them to get better insight and control of their end-to-end customer experience than ever before.",
  list: {
    title: "Scan|Tap Makes an Impact",
    items: [
      {
        icon: WalletIcon,
        title: "More Efficient Service",
        detail:
          "Scan|Tap simplifies service request and automates the request and order creation",
      },
      {
        icon: ProfileIcon,
        title: "Better Experience",
        detail: "Customers prefer Scan|Tap contactless to traditional models",
      },
      {
        icon: StarIcon,
        title: "Better Reviews",
        detail:
          "Scan|Tap operators utilize real-time customer feedback management",
      },
    ],
  },
};

const About = () => {
  return (
    <div className="about_page">
      <div className="about_page__info">
        <div className="about_page__info__image">
          <img src={data.image} alt="soloution illustration" />
        </div>
        <div className="about_page__info__text">
          <div className="about_page__info__text__title">{data.title}</div>
          <div className="about_page__info__text__details">{data.detail}</div>
        </div>
      </div>
      <div className="about_page__cards">
        <div className="about_page__cards__title">{data.list.title}</div>
        <div className="about_page__cards__items">
          {data.list.items.map((item) => (
            <div className="about_page__cards__items__item">
              <div className="about_page__cards__items__item__icon">
                <item.icon />
              </div>
              <div className="about_page__cards__items__item__content">
                <div className="about_page__cards__items__item__content__title">
                  {item.title}
                </div>
                <div className="about_page__cards__items__item__content__detail">
                  {item.detail}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="about_page__note">{data.note}</div>
    </div>
  );
};

export default About;
