import React, { FunctionComponent } from "react";
import { Field, FieldAttributes } from "formik";
import "./Input.scss";

interface InputType {
  name: string;
  placeholder?: string;
  type?: "text" | "password" | "email" | "texarea";
}

const Input: FunctionComponent<InputType> = ({
  name,
  placeholder,
  type = "text",
}) => (
  <Field
    name={name}
    render={({ field, form, meta }: FieldAttributes<any>) => (
      <div className="input_comp">
        {type === "texarea" ? (
          <textarea {...field} placeholder={placeholder} />
        ) : (
          <input type={type} {...field} placeholder={placeholder} />
        )}
        <div className="subfield">
          {meta.touched && meta.error && meta.error}
        </div>
      </div>
    )}
  />
);

export default Input;
