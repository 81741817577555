import React from "react";
import "./Pricing.scss";
import PriceTable from "../../Components/PriceTable/PriceTable";

const Pricing = () => {
  return (
    <div className="pricing_page">
      <PriceTable />
    </div>
  );
};

export default Pricing;
