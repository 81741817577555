import React, { useState } from "react";
import "./Header.scss";
// import logo from "../../assets/img/logo.png";

import { Link, NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as MenuIcon } from "../../assets/svg/menu_icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close_icon.svg";

interface MenuItemType {
  name: string;
  path: string;
  type: "internal" | "external";
}

const menuItems: MenuItemType[] = [
  { name: "Solution", path: "/solution", type: "internal" },
  { name: "Product", path: "/product", type: "internal" },
  { name: "Pricing", path: "/pricing", type: "internal" },
  {
    name: "Login",
    path: "https://dashboard.scanortap.com/login",
    type: "external",
  },
  {
    name: "Signup",
    path: "https://dashboard.scanortap.com/signup",
    type: "external",
  },
];

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="header">
      <div className="header__content">
        <div className="header__content__top">
          <Link
            className="header__content__top__logo"
            onClick={() => setMenuOpen(false)}
            to="/"
          >
            <Logo className="header__content__top__logo__img" />
          </Link>
          <div
            className={`header__content__top__menu_icon  ${
              menuOpen ? "open" : "close"
            }`}
          >
            <MenuIcon
              className="header__content__top__menu_icon__three"
              onClick={() => setMenuOpen(true)}
              style={menuOpen ? { display: "none" } : {}}
            />
            <CloseIcon
              className="header__content__top__menu_close__close"
              onClick={() => setMenuOpen(false)}
              style={!menuOpen ? { display: "none" } : {}}
            />
          </div>
        </div>
        <div className={`header__content__menu ${menuOpen ? "open" : "close"}`}>
          {menuItems.map((item) => (
            <div>
              {item.type === "internal" ? (
                <NavLink
                  activeClassName="selected"
                  onClick={() => setMenuOpen(false)}
                  to={item.path}
                >
                  {item.name}
                </NavLink>
              ) : (
                <a onClick={() => setMenuOpen(false)} href={item.path}>
                  {item.name}
                </a>
              )}
            </div>
          ))}
          <div className="signup_button">
            <Link onClick={() => setMenuOpen(false)} to="/contact">
              Book a Demo
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
