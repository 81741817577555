import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { ReactComponent as Instagram } from "../../assets/svg/instagram.svg";
import { ReactComponent as Linkedin } from "../../assets/svg/Linkedin.svg";

interface DataType {
  note: string;
  links: { name: string; url: string; type: "internal" | "external" }[];
  soicals: { name: string; icon: any; url: string }[];
}

const data: DataType = {
  note: "Copyright © 2020 Press'nXPress. All Rights Reserved.",
  links: [
    {
      name: "Privacy",
      url: "https://pressnxpress.com/privacy-policy/",
      type: "external",
    },
    {
      name: "Terms",
      url: "https://pressnxpress.com/terms-and-conditions-english/",
      type: "external",
    },
    { name: "About", url: "/about", type: "internal" },
  ],
  soicals: [
    { name: "Linkedin", icon: Linkedin, url: "#" },
    { name: "Instagram", icon: Instagram, url: "#" },
  ],
};

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__content__note">{data.note}</div>
        <div className="footer__content__actions">
          <div className="footer__content__actions__links">
            {data.links.map((link) =>
              link.type === "internal" ? (
                <Link to={link.url}>{link.name}</Link>
              ) : (
                <a href={link.url}>{link.name}</a>
              )
            )}
          </div>
          <div className="footer__content__actions__soicals">
            {data.soicals.map((soical) => (
              <soical.icon />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
