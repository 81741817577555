import React, { useState } from "react";
import "./PriceTable.scss";
import Switch from "../Switch/Switch";
import image5 from "../../assets/img/product/1.png";
import image6 from "../../assets/img/product/3.png";
import image7 from "../../assets/img/product/5.png";

interface MoneyType {
  USD: number;
  CAD: number;
}

type x1 = "monthly" | "annually";
type x2 = "USD" | "CAD";

interface PriceTableProps {
  title: string;
  items: {
    title: string;
    image: string;
    price: {
      monthly: MoneyType;
      annually: MoneyType;
    };
    features: (string | { title: string; features: string[] })[];
  }[];
}

const PriceTable = () => {
  const [currency, setCurrency] = useState<x2>("USD");
  const [period, setPeriod] = useState<x1>("monthly");

  const data: PriceTableProps = {
    title: "Pricing and Plans",
    items: [
      {
        title: "Digital Menu",
        image: image5,
        price: {
          monthly: { USD: 26, CAD: 32 },
          annually: { USD: 22, CAD: 27 },
        },
        features: [
          "Self-Declaration & Guest Contact",
          "Unlimited Menus",
          "Unlimited Menu Items",
          "Guest Self Ordering",
          "Multiple payment option",
          "Manage Orders via Order Panel",
          "One location",
          "Preorder and pay options for takeout orders",
        ],
      },
      {
        title: "Communication",
        image: image6,
        price: {
          monthly: { USD: 52, CAD: 65 },
          annually: { USD: 43, CAD: 54 },
        },
        features: [
          "Online reservations",
          "Virtual waitlist",
          "Customer feedback management",
          "Two-way customizable guest messaging",
          "Customer CRM",
        ],
      },
      {
        title: "COVID-19 Screening",
        image: image7,
        price: {
          monthly: { USD: 11, CAD: 13 },
          annually: { USD: 9, CAD: 11 },
        },
        features: [
          "Self-Declaration & Guest Contact",
          "Camera options",
          {
            title: `AI Camera [${currency === "CAD" ? "$2,499" : "$1,950"}]`,
            features: [
              "AI Body temperature monitoring",
              "AI Optical Face and Mask detection",
              "People counting",
              "Alerting system",
              "Detect 30 people simultaneously",
              "Cloud result storage",
            ],
          },
          {
            title: `Infra Temp [${currency === "CAD" ? "$199" : "$165"}]`,
            features: [
              "Touchless Forehead temperature monitoring",
              "Alerting system light and sound",
              "Wall mount",
            ],
          },
        ],
      },
    ],
  };

  return (
    <div className="pricetable">
      <div className="pricetable__title">{data.title}</div>
      <div className="pricetable__actions">
        <div className="pricetable__actions__switch">
          <span>Bill monthly</span>
          <Switch
            onChange={(check) => {
              setPeriod(check ? "annually" : "monthly");
            }}
          />
          <span>Bill annually</span>
        </div>
        <div className="pricetable__actions__currency">
          <span
            className={`pricetable__actions__currency__item ${
              currency === "USD" && "selected"
            }`}
            onClick={() => setCurrency("USD")}
          >
            USD
          </span>
          <span className="pricetable__actions__currency__divider"></span>
          <span
            className={`pricetable__actions__currency__item ${
              currency === "CAD" && "selected"
            }`}
            onClick={() => setCurrency("CAD")}
          >
            CAD
          </span>
        </div>
      </div>
      <div className="pricetable__table">
        {data.items.map((item) => (
          <div className="pricetable__table__item">
            <div className="pricetable__table__item__image">
              <img src={item.image} alt={item.title + "'s image"} />
            </div>
            <div className="pricetable__table__item__title">{item.title}</div>
            <div className="pricetable__table__item__price">
              <span>{item.price[period][currency]}</span>
              <div className="pricetable__table__item__price__sub">
                {`per month\nper location`}
              </div>
            </div>
            <ul className="pricetable__table__item__features">
              {item.features.map((feature) =>
                typeof feature === "string" ? (
                  <li className="pricetable__table__item__features__item">
                    {feature}
                  </li>
                ) : (
                  <li className="pricetable__table__item__features__item listed">
                    <div>
                      <div className="pricetable__table__item__features__item__title">
                        {feature.title}
                      </div>
                      <div className="pricetable__table__item__features__item__list">
                        {feature.features.map((ft) => (
                          <div className="pricetable__table__item__features__item__list__item">
                            {ft}
                          </div>
                        ))}
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        ))}
      </div>
      <div></div>
    </div>
  );
};

export default PriceTable;
