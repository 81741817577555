import React, { FC } from "react";
import "./Solution.scss";
import image7 from "../../assets/img/7.png";
import { ReactComponent as NavIcon } from "../../assets/svg/CardIcon/nav.svg";
import { ReactComponent as ChatIcon } from "../../assets/svg/CardIcon/chat.svg";
import { ReactComponent as UserIcon } from "../../assets/svg/CardIcon/user.svg";
import { useSpring, animated } from "react-spring";
import { Link } from "react-router-dom";

interface CardType {
  icon: any;
  color: string;
  title: string;
  list: string[];
}

interface DataType {
  showcase: { image: string; title: string; details: string };
  cards: CardType[];
}

const data: DataType = {
  showcase: {
    image: image7,
    title: "Go fully contactless with scan|tap in the new normal",
    details:
      "Our platform empowers your guests to access dine-in services fully contactless via guests’ mobile devices. It offers a 360-degree view of every guest across their entire on- and off-premise experience. scan|tap solution provides a variety of features that you can pick and choose per your specific needs.",
  },
  cards: [
    {
      icon: NavIcon,
      color: "#FF4545",
      title: "Digital Menu",
      list: [
        "Digital self-order menu",
        "Contactless payment when ordering ",
        "Contactless service request",
        "Takeout & delivery management platform",
      ],
    },
    {
      icon: ChatIcon,
      color: "#FFB550",
      title: "Communication",
      list: [
        "Two-way customizable guest messaging",
        "Online reservation",
        "Virtual waitlist ",
        "Customer feedback management",
      ],
    },
    {
      icon: UserIcon,
      color: "#8DAFFF",
      title: "Screening",
      list: [
        "Self assessment and declaration",
        "Contact tracing report ",
        "Body temperature monitoring",
        "Mask detection & people counting",
      ],
    },
  ],
};

const calc = (x: number, y: number) => [
  -(y - window.innerHeight / 2) / 20,
  (x - window.innerWidth / 2) / 20,
  1.1,
];
const trans = (x: any, y: any, s: any) => {
  // console.log(arr[0]);
  return `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
};

const Card: FC<{ data: CardType }> = ({ data }) => {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));
  return (
    <animated.div
      className="solution_page__content__cards__item"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{
        // @ts-ignore
        transform: props.xys.interpolate(trans),
        backgroundColor: data.color,
        boxShadow: `0px 2px 64px -9px ${data.color}`,
      }}
    >
      {/* <div
                style={{
                  backgroundColor: card.color,
                  boxShadow: `0px 2px 64px -9px ${card.color}`,
                }}
                className="solution_page__content__cards__item"
              > */}
      <div className="solution_page__content__cards__item__top">
        <data.icon className="solution_page__content__cards__item__top__icon" />
        <div className="solution_page__content__cards__item__top__title">
          {data.title}
        </div>
      </div>
      <div className="solution_page__content__cards__item__list">
        {data.list.map((item) => (
          <span className="solution_page__content__cards__item__list__item">
            {item}
          </span>
        ))}
      </div>
      {/* </div> */}
    </animated.div>
  );
};

const Solution = () => {
  return (
    <div className="solution_page">
      <div className="solution_page__content">
        <div className="solution_page__content__info">
          <div className="solution_page__content__info__image">
            <img src={data.showcase.image} alt="soloution illustration" />
          </div>
          <div className="solution_page__content__info__text">
            <div className="solution_page__content__info__text__title">
              {data.showcase.title}
            </div>
            <div className="solution_page__content__info__text__details">
              {data.showcase.details}
            </div>
          </div>
        </div>

        <div className="solution_page__content__cards">
          {data.cards.map((card) => (
            <Card data={card} />
          ))}
        </div>
        <div className="solution_page__content__bottom">
          <div className="solution_page__content__bottom__title">
            want to see it in action ?
          </div>
          <span className="solution_page__content__bottom__button">
            <Link to="/contact">Book a Demo</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Solution;
