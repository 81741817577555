import React from "react";
import "./Contact.scss";
import { ReactComponent as WorkIcon } from "../../assets/svg/contact/work.svg";
import { ReactComponent as MailIcon } from "../../assets/svg/contact/mail.svg";

const data = {
  infoList: [
    {
      name: "mail",
      icon: MailIcon,
      value: "info@scanortap.com",
    },
    {
      name: "address",
      icon: WorkIcon,
      value: "Toronto, ON , Canada Los Angeles, CA",
    },
  ],
};

const Contact = () => {
  return (
    <div className="contact_page">
      <div className="contact_page__content">
        <div className="contact_page__content__left">
          <div className="contact_page__content__left__title">Contact us</div>
          <div className="contact_page__content__left__list">
            {data.infoList.map((item) => (
              <div
                className="contact_page__content__left__list__item"
                key={item.name}
              >
                <item.icon className="contact_page__content__left__list__item__icon" />
                <div className="contact_page__content__left__list__item__value">
                  {item.value}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="contact_page__content__right">
          <iframe
            title="book"
            src=" https://crm.runsensible.com/WebToLead/b47f6739-5dcb-42d5-8551-354542cbc54b "
            width="100%"
            height="300px"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
