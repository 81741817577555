import React from "react";
import { Formik, Form } from "formik";
import Input from "../../Components/Input/Input";
import Submit from "../../Components/Submit/Submit";
import "./Login.scss";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <div className="login">
      <span className="login__title">Login</span>
      <Formik
        initialValues={{}}
        onSubmit={(values) => console.log(values)}
        render={(formikBag) => (
          <Form>
            <Input name="email" placeholder="Email" type="email" />
            <Input name="password" type="password" placeholder="Password" />
            <Submit />
          </Form>
        )}
      />
      <span className="login__sub">
        <Link to="forgot">Forgot your password?</Link>
      </span>
      <span className="login__sub">
        <Link to="signup">Sign up for an account</Link>
      </span>
    </div>
  );
};

export default Login;
