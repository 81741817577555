import React, { createRef } from "react";
import { Formik, Form } from "formik";
import Input from "../../Components/Input/Input";
import Submit from "../../Components/Submit/Submit";
import * as yup from "yup";

import "./Signup.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
const recaptchaRef = createRef<any>();

const validateSchema = yup.object().shape({
  email: yup.string().email().required(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  businessName: yup.string().required(),
  password: yup.string().required(),
  repassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Sss")
    .required("Password confirm is required"),
});

const Signup = () => {
  return (
    <div className="signup">
      <span className="signup__title">Signup</span>
      <Formik
        initialValues={{}}
        validationSchema={validateSchema}
        onSubmit={(values) => {
          console.log(values);
          console.log(recaptchaRef.current.getValue());
        }}
        render={(formikBag) => (
          <Form>
            <div className="row">
              <Input name="firstname" type="text" placeholder="First Name" />
              <Input name="lastname" type="text" placeholder="Last Name" />
            </div>
            <div className="row">
              <Input name="email" placeholder="Email" type="email" />
              <Input
                name="businessName"
                type="text"
                placeholder="Business Name"
              />
            </div>
            <div className="row">
              <Input name="password" type="password" placeholder="Password" />
              <Input
                name="repassword"
                placeholder="Retype password"
                type="password"
              />
            </div>
            <div className="row recaptcha">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcYJ8kZAAAAACMPdfSFl5p7qCQ33mR2WkrEBfCp"
                onChange={() => console.log("ll")}
              />
            </div>
            <Submit />
          </Form>
        )}
      />
      <span className="signup__sub">
        By signing up you accept our
        <Link to="/privacy"> Terms of use</Link>
      </span>
      <span className="signup__sub">
        Already have an account?
        <Link to="/login"> Login</Link>
      </span>
    </div>
  );
};

export default Signup;
